import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Subclass Translation", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "label": "Country", "reference": "ppv.common.ppv-countries", "source": "countryId", "source-label": "countryCode", "rules": "required", "disabled": true, "form-item": "", "default-active-first-option": "", "value": _vm.entity.countryId } })], 1), _c("a-col", { attrs: { "span": 18 } }, [_c("select-input", { attrs: { "label": "Subclass", "data-source": _vm.countryId ? _vm.subClassList : null, "source": "subClass", "source-label": "subClassDesc", "value": _vm.entity.subClass.substring(0, _vm.entity.subClass.indexOf(" ")), "rules": "required", "disabled": true, "form-item": "" } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "label": "Procurement Specialist", "reference": "ppv.common.ppv-procurement-specialist", "source": "procSpecId", "source-label": "procurementSpecialist", "rules": "required", "form-item": "", "value": _vm.entity.procSpecId }, on: { "change": _vm.onProcSpecChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditSubclassTranslations",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      subClassList: [],
      countryId: "",
      procSpecId: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  created() {
    this.countryId = this.entity.countryId;
    this.getSubClassList();
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/ppv/subclass-translations`, {
        countryId: this.countryId ? this.countryId : this.entity.countryId,
        subClass: this.entity.subClass.substring(0, this.entity.subClass.indexOf(` `)),
        procSpecId: this.procSpecId ? this.procSpecId : this.entity.procSpecId
      }).then(() => {
        this.isLoading = true;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Something went wrong, please try again!"
        });
      });
    },
    getSubClassList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?countryid=${this.countryId}`).then((res) => {
        let { data: subClassList } = res;
        subClassList = [...subClassList];
        this.subClassList = subClassList;
      });
    },
    onProcSpecChange(value) {
      this.procSpecId = value;
    },
    afterModalClose() {
      this.$router.push("/ppv-actuals/subclass-translations");
    },
    onCancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSubclassTranslations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "ppv.common.ppv-procurement-specialist", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.common.ppv-sub-class", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.subclass-translations", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/subclass-translations" } }, [_c("edit-subclass-translations")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditSubclassTranslations
  },
  data() {
    return {
      apiUrl,
      EditSubclassTranslations
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
